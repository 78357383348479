import keyBy from 'lodash/keyBy';

import type { LookupSlice } from '@/ducks/common/lookup/types';
import type { TFiltersOptions } from '@/infra/types/common/filters';
import type { VoyageDataResponse } from '@/infra/types/voyageInfo/package';
import type { AppDispatch } from '@/store';

import { CLEAR_MULTICURRENCIES, SET_DEFAULT_PRICE_RANGE } from '@/constants/actionTypes';
import { type SettingsSlice } from '@/ducks/common/settings';
import { defaultState as defaultFiltersOptions, supplementDateFilters } from '@/ducks/filtersOptions/filtersOptions';

export const populateDefaultFilter = ({
  lookup,
  searchParams,
  settings,
  voyagesData,
}: {
  lookup: LookupSlice;
  searchParams: URLSearchParams;
  settings: SettingsSlice;
  voyagesData: VoyageDataResponse;
}) => {
  const dates = supplementDateFilters(voyagesData);
  const isMNVV = !!(searchParams.get('reservationNumber') && searchParams.get('channelID'));
  const filtersDuration = settings.filters?.duration || defaultFiltersOptions.defaultFilters.durations;
  const filtersOptions = {
    ...defaultFiltersOptions,
    ...voyagesData,
    defaultFilters: {
      ...defaultFiltersOptions.defaultFilters,
      ...settings.filters,
      accessible: settings.isCabinAccessible,
      cabins: parseInt(settings.defaultCabinCount, 10),
      // settings
      currencyCode: settings.defaultCurrencyCode,
      durations: filtersDuration,
      // voyagesData
      fromDate: dates.fromDate,
      priceType: settings.pricingType,
      sailors: parseInt(settings.defaultSailorCount, 10),
      supplementDurations: filtersDuration,
      toDate: dates.toDate,
    },
    minDate: dates.minDate,
    packages: keyBy(voyagesData.packages, 'packageCode'),
    // settings
    ...(isMNVV && { minCabinOccupancy: 2 }),
    allowedCurrencies: settings.allowedCurrencies,
    defaultDurationAnimation:
      settings.filters?.defaultDurationAnimation || defaultFiltersOptions.defaultDurationAnimation,
    durationOptions: filtersDuration,
    homePorts: lookup.homePorts,
    liveChatOptions: settings.liveChat,
    loaded: true,
  };

  return filtersOptions as TFiltersOptions;
};

export const setdefaultPriceRange =
  (priceRange = {}) =>
  async (dispatch: AppDispatch) => {
    dispatch({ payload: priceRange, type: SET_DEFAULT_PRICE_RANGE });
  };

export const clearMultiCurrencies = () => async (dispatch: AppDispatch) => {
  dispatch({ type: CLEAR_MULTICURRENCIES });
};
