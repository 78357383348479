import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type TCurrencyCode } from '@/infra/types/common';

export type TStoreUplift = {
  activeCurrencyCode?: TCurrencyCode;
  isEligible?: boolean;
};

const initialState: TStoreUplift = {
  activeCurrencyCode: undefined,
  isEligible: false,
};

/**
 * Case Reducers
 */

const setIsReadyCase: CaseReducer<TStoreUplift, PayloadAction<TCurrencyCode>> = (stateDraft, { payload }) => {
  stateDraft.activeCurrencyCode = payload;
};

const setIsEligibleCase: CaseReducer<TStoreUplift, PayloadAction<boolean>> = (stateDraft, { payload }) => {
  stateDraft.isEligible = payload;
};

const clearCase: CaseReducer<TStoreUplift> = () => ({ ...initialState });

const { actions, reducer } = createSlice({
  initialState,
  name: 'uplift',
  reducers: {
    clear: clearCase,
    setIsEligible: setIsEligibleCase,
    setIsReady: setIsReadyCase,
  },
});

export const { clear, setIsEligible, setIsReady } = actions;

export default reducer;
