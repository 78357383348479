import { isProdEnv } from '../util/env';
import { getStoredValue } from '../util/storage';

const VWO_FORCE_PREFIX = 'VWO_FORCE';

export const getVWOForceOverride = (featureKey: string): boolean | undefined => {
  if (isProdEnv()) {
    return undefined;
  }

  return getStoredValue(`${VWO_FORCE_PREFIX}/${featureKey}`);
};
