import type { AnyAction } from 'redux';

import type { TCabinInvoice, TPaymentSchedule } from '@/infra/types/cabin';

import {
  SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS,
  SUMMARY_CALCULATE_INVOICE_CLEAR,
  SUMMARY_CALCULATE_INVOICE_ERROR,
  SUMMARY_CALCULATE_INVOICE_START,
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
} from '@/constants/actionTypes';

export type CalculateInvoiceState = {
  data: {
    cabinInvoices?: TCabinInvoice[];
    paymentSchedule?: TPaymentSchedule[];
  };
  error: unknown;
  isLoaded: boolean;
  isLoading: boolean;
};

const defaultState = {
  data: {} as CalculateInvoiceState['data'],
  error: {},
  isLoaded: false,
  isLoading: false,
} as CalculateInvoiceState;

export default function calculateInvoiceReducer(store = defaultState, action: AnyAction): CalculateInvoiceState {
  switch (action.type) {
    case SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS: {
      return {
        ...store,
        data: {
          ...store.data,
          cabinInvoices: [
            {
              ...store.data.cabinInvoices?.[0],
              bookingPriceDetails: {
                ...store.data.cabinInvoices?.[0]?.bookingPriceDetails,
                amount: action.payload,
              },
            },
          ],
        },
        error: {},
        isLoading: false,
      };
    }
    case SUMMARY_CALCULATE_INVOICE_CLEAR:
      return defaultState;
    case SUMMARY_CALCULATE_INVOICE_ERROR:
      return { ...store, data: {}, error: action.payload, isLoading: false };
    case SUMMARY_CALCULATE_INVOICE_START:
      return { ...store, isLoading: true };
    case SUMMARY_CALCULATE_INVOICE_SUCCESS:
      return { ...store, data: action.payload, error: {}, isLoaded: true, isLoading: false };
    default:
      return store;
  }
}
