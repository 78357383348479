import orderBy from 'lodash/orderBy';

import { getIsTaxesIncluded } from '@/helpers/pricing/lib/tax';
import { FiltersPriceType } from '@/infra/types/common/filters';

export const getAmountPerItemNew = (
  price,
  { cabins = 1, duration, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  switch (priceType) {
    case FiltersPriceType.perCabin:
      return getAmountTaxAware(price, key) / cabins;
    case FiltersPriceType.perSailor:
      return getAmountTaxAware(price, key) / sailors;
    case FiltersPriceType.sailorPerNight:
      return getAmountPerVoyage(price, duration, { sailors }, key);
    default:
      return getAmountTaxAware(price, key) / sailors;
  }
};

export const getAmountTaxAware = (price = {}, key = 'amount') => {
  const { taxAmount } = price;
  const isTaxesIncluded = getIsTaxesIncluded();
  const result = parseFloat(price[key]);
  if (isTaxesIncluded || typeof taxAmount !== 'number') {
    return result;
  }

  return result - parseFloat(taxAmount);
};

export const getAmountPerVoyage = (price, duration, { sailors }, key = 'amount') =>
  getAmountTaxAware(price, key) / sailors / duration;

export const getAmountPerVoyageFlat = (price, duration, { sailors }) => price / sailors / duration;

export const getSailingForSailorPerNight = (sailings, { sailors }) => {
  const toBeAppliedPriceRange = {};
  const newSailings = orderBy(sailings, (s) => getAmountPerVoyage(s.startingPrice, s.duration, { sailors }), 'asc');
  if (newSailings.length === 0) {
    return {};
  }

  toBeAppliedPriceRange.minPrice =
    newSailings && Math.floor(getAmountPerVoyage(newSailings[0].startingPrice, newSailings[0].duration, { sailors }));
  toBeAppliedPriceRange.maxPrice =
    newSailings &&
    Math.ceil(
      getAmountPerVoyage(
        newSailings[newSailings.length - 1].startingPrice,
        newSailings[newSailings.length - 1].duration,
        { sailors },
      ),
    );
  return { newSailings, toBeAppliedPriceRange };
};

export const getAmountPerItem = (
  price,
  { cabins = 1, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  if (priceType === FiltersPriceType.perCabin || priceType === FiltersPriceType.sailorPerNight) {
    return getAmountTaxAware(price, key) / cabins;
  }
  if (priceType === FiltersPriceType.perSailor) {
    return getAmountTaxAware(price, key) / sailors;
  }
  return getAmountTaxAware(price, key) / sailors;
};

export const getSailings = (sailings, { cabins, priceType, sailors }) => {
  const toBeAppliedPriceRange = {};
  const newSailings = orderBy(
    sailings,
    (s) => getAmountPerItem(s.startingPrice, { cabins, priceType, sailors }),
    'asc',
  );
  try {
    toBeAppliedPriceRange.minPrice =
      newSailings && Math.floor(getAmountPerItem(newSailings[0].startingPrice, { cabins, priceType, sailors }));
    toBeAppliedPriceRange.maxPrice =
      newSailings &&
      Math.ceil(getAmountPerItem(newSailings[newSailings.length - 1].startingPrice, { cabins, priceType, sailors }));
  } catch (e) {
    // console.log('error while cal min and max price', e);
  }
  return { newSailings, toBeAppliedPriceRange };
};
