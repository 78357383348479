import { addMonths, differenceInCalendarMonths, isAfter, isBefore, isEqual as isEqualDates, isValid } from 'date-fns';
import isEqual from 'lodash/isEqual';

import type { RootState } from '@/store';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getSearchParamsValue, isDateValidNative } from '@/ducks/filters/utils';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { selectMnvvVoyage } from '@/ducks/mnvv/selectors';
import { calculateMaxDate, getMnvvReservation } from '@/ducks/mnvv/utils';
import { format, parse } from '@/helpers/util/dateUtil';

type RawDate = null | string;

const fitDateInMinMaxPeriod = (date: RawDate, minDate: Date | null, maxDate: Date | null) => {
  const d = parse(date);

  if (minDate && (!d || !isValid(d) || isBefore(d, minDate))) {
    return minDate;
  }

  if (maxDate && d && isAfter(d, maxDate)) {
    return maxDate;
  }

  return isValid(d) ? d : null;
};

const getMinMaxDatePeriod = (state: RootState, searchParams: URLSearchParams) => {
  const { isMNVV } = getMnvvReservation(searchParams);
  const options = getFiltersOptions(state);
  const voyage = selectMnvvVoyage(state);

  const minDate = parse(options.minDate);
  let maxDate = options.maxDate ? parse(options.maxDate) : null;

  if (isMNVV && voyage?.bookedDate) {
    maxDate = calculateMaxDate(voyage?.bookedDate) || maxDate;
  }

  return { maxDate: isValid(maxDate) ? maxDate! : null, minDate: isValid(minDate) ? minDate! : null };
};

const getDatesPeriod = (
  state: RootState,
  searchParams: URLSearchParams,
  fromDate: null | string,
  toDate: null | string,
) => {
  const { maxDate, minDate } = getMinMaxDatePeriod(state, searchParams);

  const options = getFiltersOptions(state);
  const from = fitDateInMinMaxPeriod(fromDate || options.defaultStartDate, minDate, maxDate);
  let to = fitDateInMinMaxPeriod(toDate || options.defaultEndDate, minDate, maxDate);

  if (from && to) {
    if ((!isBefore(from, to) && !isEqual(from, to)) || (fromDate && !toDate)) {
      const parsedDefaultEndDate = options.defaultEndDate && parse(options.defaultEndDate);
      const parsedDefaultStartDate = options.defaultStartDate && parse(options.defaultStartDate);
      const defaultDateLength =
        isValid(parsedDefaultEndDate) && isValid(parsedDefaultStartDate)
          ? differenceInCalendarMonths(parsedDefaultEndDate as Date, parsedDefaultStartDate as Date)
          : 0;
      const parsedMaxDate = maxDate && parse(maxDate);
      const parsedFrom = from && parse(from);
      const diffFromMaxDate =
        isValid(parsedMaxDate) && isValid(parsedFrom) ? differenceInCalendarMonths(parsedMaxDate!, parsedFrom!) : 0;

      to = diffFromMaxDate > defaultDateLength ? format(addMonths(from, defaultDateLength)) : maxDate;
    }
    return { fromDate: format(from) as string, toDate: format(to) as string };
  }

  return {
    fromDate: options.defaultStartDate || null,
    toDate: options.defaultEndDate || null,
  };
};

const isFromDateValid = ({
  fromDate,
  searchParams,
  state,
  toDate,
}: {
  fromDate: string | Date | null;
  searchParams: URLSearchParams;
  state: RootState;
  toDate: string | Date | null;
}) => {
  const { maxDate } = getMinMaxDatePeriod(state, searchParams);

  const fromSearchParams = getSearchParamsValue(FILTER_KEY.dateFrom, searchParams);
  const toSearchParams = getSearchParamsValue(FILTER_KEY.dateTo, searchParams);

  let from = fromSearchParams || fromDate;
  const to = toSearchParams || toDate;

  if (!to || !from) return true;

  if (!isDateValidNative(from as string)) {
    return false;
  }

  from = new Date((from as string).replace(/-/g, '/'));

  const isFromDateMoreThanNow = isBefore(new Date(), from);
  const isFromDateLessThenToDate = isBefore(from, new Date(to as string)) || isEqualDates(from, new Date(to as string));
  const isFromDateMoreThanMaxDate = maxDate ? isBefore(from, maxDate) || isEqualDates(from, maxDate) : true;

  return isFromDateMoreThanMaxDate && isFromDateMoreThanNow && isFromDateLessThenToDate;
};

const isToDateValid = ({
  searchParams,
  state,
  toDate,
}: {
  searchParams: URLSearchParams;
  state: RootState;
  toDate: string | Date | null;
}) => {
  const { maxDate } = getMinMaxDatePeriod(state, searchParams);

  const toSearchParams = getSearchParamsValue(FILTER_KEY.dateTo, searchParams);

  let to = toSearchParams || toDate;

  if (!to) return true;

  if (!isDateValidNative(to as string)) {
    return false;
  }

  to = new Date((to as string).replace(/-/g, '/'));

  const isToDateMoreThanNow = isBefore(new Date(), to);
  const isToDateLessThanMaxDate = maxDate ? isBefore(to, maxDate) || isEqualDates(to, maxDate) : true;

  // return isDateDayValidInMonth(to) && isToDateMoreThanNow && isToDateLessThanMaxDate;
  return isToDateMoreThanNow && isToDateLessThanMaxDate;
};

const fromDate: FiltersConfigValue<FiltersTypes['dateFrom']> = {
  getValue: ({ searchParams, state, value }) => {
    return getDatesPeriod(
      state,
      searchParams,
      value as string,
      getSearchParamsValue(FILTER_KEY.dateTo, searchParams) as string,
    ).fromDate;
  },
  isValueValidForURL: ({ searchParams, state, value }) => {
    const { fromDate, toDate } = getDatesPeriod(
      state,
      searchParams,
      value as string,
      getSearchParamsValue(FILTER_KEY.dateTo, searchParams) as string,
    );

    return isFromDateValid({ fromDate, searchParams, state, toDate });
  },
};

const toDate: FiltersConfigValue<FiltersTypes['dateTo']> = {
  getValue: ({ searchParams, state, value }) => {
    return getDatesPeriod(
      state,
      searchParams,
      getSearchParamsValue(FILTER_KEY.dateFrom, searchParams) as string,
      value as string,
    ).toDate;
  },

  isValueValidForURL: ({ searchParams, state, value }) => {
    const { toDate } = getDatesPeriod(
      state,
      searchParams,
      getSearchParamsValue(FILTER_KEY.dateFrom, searchParams) as string,
      value as string,
    );

    return isToDateValid({ searchParams, state, toDate });
  },
};

export { fromDate, toDate };
