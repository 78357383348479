import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import type { TCurrencyCode } from '@/infra/types/common';

import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { FILTER_KEY } from '@/ducks/filters/types';
import { selectInvoiceCurrencyCode } from '@/ducks/pages/summary/selectors';
import { getSearchParam } from '@/ducks/routes/history';

const appointTaxInclusiveByCurrencyCode = (_currencyCode: TCurrencyCode) => {
  return true;

  // TODO: VWO flag is in async context, that is not accessible here
  // For now we don't use that flag anyway
  // return ['AUD', 'GBP', 'NZD'].includes(currencyCode) || defaultValue;
};

export const selectIsTaxIncluded = createSelector(
  selectCurrencyCode,
  selectInvoiceCurrencyCode,
  (filterCurrencyCode, invoiceCurrencyCode) =>
    appointTaxInclusiveByCurrencyCode(invoiceCurrencyCode || filterCurrencyCode),
);

export const useGetIsTaxIncluded = () => {
  return useSelector(selectIsTaxIncluded);
};

export const getIsTaxesIncluded = () => {
  const currencyCode = getSearchParam(FILTER_KEY.currencyCode);
  return appointTaxInclusiveByCurrencyCode(currencyCode);
};
