import { createReducer, type PayloadAction } from '@reduxjs/toolkit';

import type { FilteredPackage, PackagesData, TVoyagesApiPayload } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { groupPackagesWithSameName } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';

import {
  NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS,
  NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
  NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
  NEW_CHOOSE_VOYAGE_SEARCH_START,
  NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
  NEW_GENERIC_CATEGORY_CODE_DATA,
} from './actionTypes';

export type TMainPackages = Pick<PackagesData, 'defaultPackages' | 'packages' | 'sailings'> & {
  extendedPackages: FilteredPackage[];
  isLoaded?: boolean;
};

export type TFilteredPackages = {
  isLoaded?: boolean;
  packages: FilteredPackage[];
  sailings?: SailingFromPackages[];
};

export type TGenericCategories = Pick<PackagesData, 'defaultGenericCategoryCodes' | 'genericCategoryCodes'>;

type State = TGenericCategories & {
  error: boolean;
  filteredPackages: TFilteredPackages;
  isLoading?: boolean;
  isVoyageInvalid: boolean;
  mainPackages: TMainPackages;
  trackedSailings: string[];
  voyagesApiPayload?: TVoyagesApiPayload;
};

export const defaultState: State = {
  defaultGenericCategoryCodes: [],
  error: false,
  // filtered packages array after filter selection
  filteredPackages: { isLoaded: false, packages: [], sailings: [] },
  genericCategoryCodes: [],
  isLoading: false,
  isVoyageInvalid: false,
  mainPackages: {
    defaultPackages: [],
    extendedPackages: [],
    isLoaded: false,
    packages: [],
    sailings: [],
  },
  trackedSailings: [],
  voyagesApiPayload: undefined,
};

const chooseVoyageNew = createReducer(defaultState, (builder) =>
  builder
    .addCase<string, PayloadAction<TVoyagesApiPayload>>(NEW_CHOOSE_VOYAGE_SEARCH_START, (draft, { payload }) => {
      draft.error = false;
      draft.isLoading = true;
      draft.voyagesApiPayload = payload;
    })
    .addCase<string, PayloadAction<{ packages: FilteredPackage[]; sailings: SailingFromPackages[] }>>(
      NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
      (draft, { payload }) => {
        draft.error = false;
        draft.isLoading = false;
        draft.filteredPackages = {
          isLoaded: true,
          packages: payload.packages,
          sailings: payload.sailings,
        };
      },
    )
    .addCase(NEW_CHOOSE_VOYAGE_SEARCH_FAILURE, (draft) => {
      draft.error = true;
      draft.isLoading = false;
      draft.voyagesApiPayload = undefined;
      draft.filteredPackages.packages = [];
      draft.filteredPackages.sailings = [];
    })
    .addCase<string, PayloadAction<PackagesData>>(NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS, (draft, { payload }) => {
      draft.mainPackages = {
        defaultPackages: payload.defaultPackages,
        extendedPackages: groupPackagesWithSameName(payload.packages),
        isLoaded: true,
        packages: payload.packages,
        sailings: payload.sailings,
      };
    })
    .addCase<string, PayloadAction<string[]>>(NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS, (draft, { payload }) => {
      draft.trackedSailings = [...draft.trackedSailings, ...payload];
    })
    .addCase<string, PayloadAction<TGenericCategories>>(NEW_GENERIC_CATEGORY_CODE_DATA, (draft, { payload }) => {
      draft.defaultGenericCategoryCodes = payload.defaultGenericCategoryCodes;
      draft.genericCategoryCodes = payload.genericCategoryCodes;
    }),
);

export default chooseVoyageNew;
