import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

type State = {
  errorMessage: string | null;
  id: TCabinCategory['code'] | null;
};

const initialState: State = {
  errorMessage: null,
  id: null,
};

const chosenCategorySlice = createSlice({
  initialState,
  name: 'voyageInfo/chosen-category',
  reducers: {
    clearChosenCategory: () => {
      return initialState;
    },
    setChosenCategory: (state, { payload: code }: PayloadAction<TCabinCategory['code']>) => {
      state.id = code;
    },
    setChosenCategoryError: (state, { payload: errorMessage }: PayloadAction<string>) => {
      state.errorMessage = errorMessage;
    },
  },
});

export const { clearChosenCategory, setChosenCategory, setChosenCategoryError } = chosenCategorySlice.actions;
export const chosenCategoryReducer = chosenCategorySlice.reducer;
