import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

export const ACCESS_KEY_STATES = {
  DETAIL: 'DETAIL',
  EMPTY: 'EMPTY',
  INIT: 'INIT',
  INVALID: 'INVALID',
  VALID: 'VALID',
} as const;

export const getIsAccessKeyStateValid = (state: keyof typeof ACCESS_KEY_STATES) => state === ACCESS_KEY_STATES.DETAIL;

export const ACCESS_KEY_SOURCE = {
  MANUAL_ENTERING: 'MANUAL_ENTERING',
  URL: 'URL',
} as const;

type AccessKeyState = {
  accessKey?: string | null;
  error?: string | unknown;
  filterSailing?: boolean;
  isDataReady: boolean;
  promoCode?: string | null;
  source?: keyof typeof ACCESS_KEY_SOURCE;
  state: keyof typeof ACCESS_KEY_STATES;
};

const initialState: AccessKeyState = {
  accessKey: undefined,
  error: undefined,
  filterSailing: false,
  isDataReady: false,
  promoCode: undefined,
  source: undefined,
  state: ACCESS_KEY_STATES.EMPTY,
};

const accessKeysSlice = createSlice({
  initialState,
  name: 'accessKeys',
  reducers: {
    accessKeyClear() {
      return { ...initialState, isDataReady: true };
    },
    accessKeyDataIsReady(state) {
      state.isDataReady = true;
    },
    accessKeyDetailsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isDataReady = true;
      state.state = ACCESS_KEY_STATES.INVALID;
    },
    accessKeyDetailsSuccess(state, action: PayloadAction<Partial<AccessKeyState>>) {
      return {
        ...state,
        ...action.payload,
        error: undefined,
        isDataReady: true,
        state: ACCESS_KEY_STATES.DETAIL,
      };
    },
    accessKeyInited(state, action: PayloadAction<string>) {
      return {
        ...initialState,
        accessKey: action.payload,
        state: ACCESS_KEY_STATES.INIT,
      };
    },
    accessKeyValidateError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isDataReady = true;
      state.state = ACCESS_KEY_STATES.INVALID;
    },
    accessKeyValidateSuccess(state, action: PayloadAction<Partial<AccessKeyState> & { source: string }>) {
      return {
        ...state,
        ...action.payload,
        error: undefined,
        source: action.payload.source,
        state: ACCESS_KEY_STATES.VALID,
      };
    },
  },
});

export const {
  accessKeyClear,
  accessKeyDataIsReady,
  accessKeyDetailsError,
  accessKeyDetailsSuccess,
  accessKeyInited,
  accessKeyValidateError,
  accessKeyValidateSuccess,
} = accessKeysSlice.actions;

export const selectAccessKeySlice = (state: RootState) => state.accessKeys;
export const selectAccessKeysIsDataReady = (state: RootState) => selectAccessKeySlice(state)?.isDataReady;
export const selectAccessKey = (state: RootState) => selectAccessKeySlice(state)?.accessKey;
export const selectAccessKeyPromoCode = (state: RootState) =>
  getIsAccessKeyStateValid(selectAccessKeySlice(state).state) ? selectAccessKeySlice(state)?.promoCode : undefined;
export const selectAccessKeyState = (state: RootState) => selectAccessKeySlice(state)?.state;

export default accessKeysSlice.reducer;
