export enum CABIN_CATEGORIES_ERROR_TYPES {
  UNKNOWN = 'unknown',
  ALL_EXHAUSTED = 'all-exhausted',
  FETCH_ERROR = 'fetch-error',
}

export type CabinCategoryError = {
  message?: string | null;
  type?: CABIN_CATEGORIES_ERROR_TYPES | null;
};
