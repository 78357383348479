import { type ReadonlyURLSearchParams } from 'next/navigation';

import { type TUrlParams } from '@/infra/types/common';
import { type TOptional } from '@/types/common';

type TArg = URLSearchParams | ReadonlyURLSearchParams | null;

const searchParamsAsObject = <R extends TUrlParams = TUrlParams>(searchParams?: TArg): TOptional<R> => {
  type TKey = keyof R;
  type TValue = R[TKey];
  if (searchParams?.toString()?.length) {
    const result = {} as R;
    [...searchParams.entries()].forEach(([name, value]: [TKey, string]) => {
      if (name in result) {
        const prev = result[name];
        const next: string[] = Array.isArray(prev) ? prev : [prev!];
        next.push(value);
        result[name] = next as TValue;
      } else {
        result[name] = value as TValue;
      }
    }, {} as R);
    return result;
  }
};

export default searchParamsAsObject;
