import {
  differenceInDays,
  differenceInSeconds,
  differenceInYears,
  isDate,
  isValid,
  format as libFormat,
  parseISO,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { isProdEnv } from './env';

export const isGuestAdultBySailingDate = (sailingDate, guestDOB) => {
  const year = differenceInYears(new Date(sailingDate), new Date(guestDOB));
  return year >= 18;
};

// returns current time in milliseconds
export const getCurrentTimeInMilliseconds = () => {
  const currentTimeInMilliseconds = new Date().valueOf();
  return currentTimeInMilliseconds;
};

// 'parse(args)' is used instead of 'new Date(args)'
// parseISO doesn't fall back to new Date constructor if it fails to parse a string argument. Instead, it returns Invalid Date. https://date-fns.org/v2.9.0/docs/parseISO
export const parse = (str, isStrict) => {
  if (str instanceof Date) {
    return str;
  }
  if (str) {
    const date = parseISO(str);
    // If the parsing does not find the correct date, it will return the "Invalid Date" (= new Date(NaN)) value
    return date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
  }
  if (!isStrict) {
    return new Date(str);
  }
};

// Standard date format for API calls for dates
export const format = (date, formatString = 'yyyy-MM-dd', progressiveSumDtFlag = false) => {
  if (!date && !isValid(date)) {
    return undefined;
  }
  try {
    if (progressiveSumDtFlag) {
      return libFormat(new Date(date), formatString);
    }
    return libFormat(new Date(date), formatString);
  } catch (e) {
    console.error('date format error: ', e);
    return date;
  }
};

export const getDatesRange = ({ dayFormat = 'dd', from, monthFormat = 'MMM', to, yearFormat = 'yyyy' }) => {
  if (!from || !to) {
    return '';
  }

  const fDate = parse(from);
  const tDate = parse(to);
  if (!isValid(fDate) || !isValid(tDate)) {
    return '';
  }

  const sameYear = fDate.getFullYear() === tDate.getFullYear();
  const sameMonth = fDate.getMonth() === tDate.getMonth();

  const fMonth = format(fDate, monthFormat);
  const fDay = format(fDate, dayFormat);
  const fYear = format(fDate, yearFormat);

  const tDay = format(tDate, dayFormat);
  const tMonth = format(tDate, monthFormat);
  const tYear = format(tDate, yearFormat);

  if (sameYear && sameMonth) {
    return `${fMonth} ${fDay}-${tDay} ${tYear}`;
  }

  if (sameYear) {
    return `${fMonth} ${fDay} - ${tMonth} ${tDay} ${tYear}`;
  }

  return `${fMonth} ${fDay} ${fYear} - ${tMonth} ${tDay} ${tYear}`;
};

export const formatDayByDate = (date, formatString = 'yyyy-MM-dd') => {
  if (!date) {
    return undefined;
  }
  const dateToFormat = isDate(date) ? date : parse(date);
  return `${libFormat(new Date(dateToFormat), 'iii')}, ${libFormat(dateToFormat, formatString, { weekStartsOn: 0 })}`;
};

export const formatDate = (date, formatString = 'yyyy-MM-dd') => {
  if (!date) {
    return '';
  }

  const dateToFormat = isDate(date) ? date : parse(date);
  return libFormat(dateToFormat, formatString);
};

export const currentNYTime = (serverDate) => {
  const utc = serverDate || new Date().toISOString();
  try {
    return toZonedTime(utc, 'America/New_York');
  } catch (e) {
    // IE11 fallback: it doesn't support IANA time zone names
    return toZonedTime(utc, '-04:00');
  }
};

export const getCurrentDateTime = (serverDate) => {
  // hotfix, for PROD - NY timezone (-4), for NONPROD - UTC(0), https://virginvoyages.atlassian.net/browse/MSH-98463
  if (isProdEnv()) {
    return currentNYTime(serverDate);
  }

  return new Date();
};

export const isUserIsAbove13 = (dob, currentDate) => isUserAtLeastAge(dob, currentDate, 13);
export const isUserAtLeastAge = (dob, currentDate, age) => {
  const year = differenceInYears(currentDate, new Date(dob));
  return year >= age;
};

export const durationInSecondsUntil = (time) => differenceInSeconds(parse(time), getCurrentDateTime()) * 1000;

export const durationInDaysUntil = (startTime, endTime) => differenceInDays(parse(startTime), currentNYTime(endTime));

// Standard date format for datepicker label
export const formatMonthLabel = (date) => format(date, 'MMM yyyy');

export const completeMonthLabel = (date) => formatDate(date, 'MMMM yyyy');

export const completeDateLabel = (date) => formatDayByDate(date, 'MMM dd');

export const reducedMonthAndYear = (date) => format(date, 'MMM-yyyy');
