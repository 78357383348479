import {
  CABIN_CATEGORY_DATA_LOADING,
  CABIN_CATEGORY_SET_UNAVAILABLE,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS,
  CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
  SEAT_MAP_VR_LOADED,
} from '@/constants/actionTypes';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import sortCabinCategories from '@/ducks/pages/chooseCabin/helpers/sortCabinCategoriesByType';
import { CABIN_CATEGORIES_ERROR_TYPES } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/constants';

const defaultState = {
  apiPayload: undefined,
  data: [],
  error: { message: null, type: null },
  isLoaded: false,
  isLoading: false,
  isSeatMapVrLoaded: false,
};

export default function cabinCategoriesReducer(store = defaultState, action = {}) {
  switch (action.type) {
    case CABIN_CATEGORY_DATA_LOADING:
      return { ...store, apiPayload: action.payload, isLoading: true };
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR:
      return defaultState;
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR:
      return { ...store, apiPayload: undefined, data: [], error: action.payload, isLoaded: true, isLoading: false };
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS:
      return {
        ...store,
        actualData: action.payload.cabinCategories,
        apiPayload: store.apiPayload,
        data: prepareCabinCategories(action.payload.cabinCategories)?.map((category) =>
          customSubMetasGrouping(category),
        ),
        error: action.payload.cabinCategories?.length
          ? defaultState.error
          : { type: CABIN_CATEGORIES_ERROR_TYPES.FETCH_ERROR },
        isLoaded: true,
        isLoading: false,
        tracked: [],
      };
    case CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN: {
      const data = getCabinCategoriesUpdatedWithUnavailability(
        action.payload.cabinCategories,
        action.payload.subCategoryCode,
      );
      return {
        ...store,
        data,
      };
    }
    case SEAT_MAP_VR_LOADED:
      return {
        ...store,
        isSeatMapVrLoaded: true,
      };
    case CABIN_CATEGORY_SET_UNAVAILABLE:
      return {
        ...store,
        data: sortCabinCategories(
          updateCabinCategoryAvailability(store.data, action.payload.exhaustedCategoryCode),
          action.payload.chooseCabinSort,
        ),
      };
    default:
      return store;
  }
}

const updateCabinCategoryAvailability = (categories, code) =>
  [...categories].map((category) => (category.code === code ? { ...category, isAvailable: false } : { ...category }));

const customSubMetasGrouping = (category) => {
  const grouping = {
    code: CABIN_CATEGORY_CODES.SEA_TERRACE,
    subMetaCode: 'TR',
    subMetaCodes: ['TL', 'TR', 'TC'],
  };
  if (category.code === grouping.code) {
    const rootSubMeta = category?.submetas?.find((subMeta) => subMeta.code === grouping.subMetaCode);

    if (rootSubMeta) {
      const categoryCopy = { ...category };
      const rootSubMetaCopy = { ...rootSubMeta };
      rootSubMetaCopy.cabinTypes = [
        ...categoryCopy.submetas.filter((subMeta) => grouping.subMetaCodes.includes(subMeta.code)),
      ];
      categoryCopy.submetas = [
        rootSubMetaCopy,
        ...categoryCopy.submetas.filter((subMeta) => !grouping.subMetaCodes.includes(subMeta.code)),
      ];

      return categoryCopy;
    }

    return category;
  }

  return category;
};

const updateSubMetasAvailability = (subMetas, unavailableCabinTypeCode) =>
  subMetas.map((subMeta) => {
    const cabinTypes = subMeta.cabinTypes.map((cabinType) => ({
      ...cabinType,
      ...(cabinType.code === unavailableCabinTypeCode ? { isAvailable: false } : {}),
    }));
    return {
      ...subMeta,
      cabinTypes,
      isAvailable: cabinTypes.some((ct) => ct.isAvailable),
    };
  });

const sortSubMetasByAvailability = (subMetas) =>
  subMetas
    .toSorted((a, b) => parseInt(a.navigationOrder, 10) - parseInt(b.navigationOrder, 10))
    .toSorted((a, b) => {
      const isAAvailable = a.isAvailable;
      const isBAvailable = b.isAvailable;

      if (isAAvailable && isBAvailable) {
        return 0;
      }

      return isAAvailable ? -1 : 1;
    });

const getCabinCategoriesUpdatedWithUnavailability = (cabinCategories, unavailableCabinTypeCode) =>
  cabinCategories.map((cabinCategory) => {
    const submetas = sortSubMetasByAvailability(
      updateSubMetasAvailability(cabinCategory.submetas, unavailableCabinTypeCode),
    );
    return {
      ...cabinCategory,
      isAvailable: submetas.some((sm) => sm.isAvailable),
      submetas,
    };
  });

const sortByNavigationOrderDesc = (a, b) => a.navigationOrder - b.navigationOrder;

/**
 * @see https://virginvoyages.atlassian.net/browse/MSH-111937?focusedCommentId=920984
 */
const prepareCabinCategories = (cabinCategories) =>
  cabinCategories
    ?.map((category) => ({
      ...category,
      isAvailable: category.submetas.some((subMeta) => subMeta.isAvailable),
      submetas: category.submetas
        ?.filter((item) => !!item.name)
        .map((submeta) => ({ ...submeta, cabinTypes: submeta.cabinTypes.toSorted(sortByNavigationOrderDesc) }))
        .toSorted(sortByNavigationOrderDesc),
    }))
    .toSorted(sortByNavigationOrderDesc);

export const getMaximumDiscountPriceAmongCabinTypes = (cabinTypes) =>
  cabinTypes.reduce(
    (prevCabinPrice, cabinType) =>
      cabinType.lowestAvailablePrice.totalPrice.discount > prevCabinPrice.totalPrice.discount
        ? cabinType.lowestAvailablePrice
        : prevCabinPrice,
    cabinTypes?.[0]?.lowestAvailablePrice || {},
  );

export const checkForAllCabinExhaust = (cabinCategories) =>
  cabinCategories.some((cabin) => cabin.submetas.some((subMeta) => subMeta.isAvailable));
