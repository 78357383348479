import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { FiltersSortType } from '@/infra/types/common/filters';

const sortLowestPriceFirst = (cabinCategories: TCabinCategory[]) =>
  [...cabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (a.lowestSubCategoryPrice?.totalPrice?.amount || 0) - (b.lowestSubCategoryPrice?.totalPrice?.amount || 0),
  );

const sortHighestPriceFirst = (cabinCategories: TCabinCategory[]) =>
  [...cabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (b.lowestSubCategoryPrice?.totalPrice?.amount || 0) - (a.lowestSubCategoryPrice?.totalPrice?.amount || 0),
  );

const sortByDefault = (cabinCategories: TCabinCategory[]) =>
  [...cabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (+a.recommendedSortPriority || 0) - (+b.recommendedSortPriority || 0),
  );

const sortCabinCategoriesByType = (cabinCategories: TCabinCategory[], sortType: FiltersSortType) => {
  switch (sortType) {
    case FiltersSortType.priceAsc:
      return sortLowestPriceFirst(cabinCategories);
    case FiltersSortType.priceDesc:
      return sortHighestPriceFirst(cabinCategories);
    case FiltersSortType.recommended:
      return sortByDefault(cabinCategories);
    default:
      return sortByDefault(cabinCategories);
  }
};

export default sortCabinCategoriesByType;
