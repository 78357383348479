import { ReadonlyURLSearchParams } from 'next/navigation';

import { addYears } from 'date-fns';

import { type TUrlParams } from '@/infra/types/common';

import { MNVV_OFFER_LAUNCH_DATE, MNVV_OFFER_VALIDITY_IN_YEARS, MNVVSearchParams } from './constants';

export const calculateMaxDate = (inputDate?: string) => {
  const date = new Date(inputDate || MNVV_OFFER_LAUNCH_DATE);
  return addYears(date, MNVV_OFFER_VALIDITY_IN_YEARS);
};

export const makeSearchParamGetter = (searchParams: URLSearchParams | ReadonlyURLSearchParams | TUrlParams) =>
  searchParams instanceof URLSearchParams || searchParams instanceof ReadonlyURLSearchParams
    ? (name: string) => searchParams.get(name)
    : (name: string) => Reflect.get(searchParams, name) as string;

export const getMnvvReservation = (searchParams: URLSearchParams | ReadonlyURLSearchParams | TUrlParams) => {
  const getParam = makeSearchParamGetter(searchParams);
  const agencyId = getParam(MNVVSearchParams.agencyId);
  const agentId = getParam(MNVVSearchParams.agentId);
  const channelID = getParam(MNVVSearchParams.channelID);
  const reservationNumber = getParam(MNVVSearchParams.reservationNumber);

  return {
    agencyId,
    agentId,
    channelID,
    isMNVV: !!(channelID && reservationNumber),
    reservationNumber,
  };
};
