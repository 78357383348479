export type VWOFeatureFlags = Record<VWOFlag, boolean>;

export const enum VWOFlag {
  BOOK_NOW = 'bookingHoldLogicChangeCampaign',
  EMAIL_OPT_IN_RADIO = 'bookingEmailOptInCampaign',
  MNVV_OFFER_REVISION = 'MNVVOnboardOfferRevisionCampaign',
  MOBILE_OPTIN = 'mobileOptInFeatureCampaign',
  SIZZLE_REELS = 'sizzleReelsCampaign',
  TAXES_AND_FEES = 'bookingTaxesAndFeesCampaign',
  VPS_PAYMENT = 'bookingVpsMigrationFeatureFlag',
  PBC_PAYMENT = 'bookPaymentByComponent',
  WHATS_INCLUDED = 'valuePropModuleFeatureFlag',
  CHOOSE_CABIN_30 = 'bookingChooseCabin30Feature',
  SUMMARY_30 = 'bookingSummary30Campaign',
  FARE_CLASSES = 'bookFareClasses_FF',
}

export const enum WHATS_INCLUDED_KEYS {
  CHOOSE_VOYAGE_PAGE = 'chooseVoyagePage',
  META_CATEGORY_PAGE = 'metaCategoryPage',
  PAYMENT_PAGE = 'paymentPage',
  SUB_META_CATEGORY_PAGE = 'subMetaCategoryPage',
  SUMMARY_PAGE = 'summaryPage',
}

export const enum VWOGoal {
  EMAIL_OPT_IN_RADIO_BOOKING_COMPLETIONS = 'bookingEmailOptInGoal_BookingCompletions',
  EMAIL_OPT_IN_RADIO_MOVE_FORWARD = 'bookingEmailOptInGoal_MoveForward',
  CHOOSECABIN_BOOKING_COMPLETIONS = 'bookingChooseCabin30Goal_BookingCompletions',
  MOVE_FROM_META = 'bookingChooseCabin30Goal_MoveChooseMetaPage',
  MOVE_FROM_SUBMETA = 'bookingChooseCabin30Goal_MoveChooseSubMetaPage',
  SUMMARY_BOOKING_COMPLETIONS = 'bookingSummary30Goal_BookingCompletions',
  PAYMENT_MOVE = 'bookingSummary30Goal_paymentMove',
}
