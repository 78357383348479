import type { TCabinInvoice, TPaymentSchedule } from '@/infra/types/cabin/invoice';
import type { TBookingPriceDetailDiscount } from '@/infra/types/cabin/invoice';
import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { TSailingData } from '@/infra/types/voyageInfo/sailing';

import UIResource from '@/components/UIResource';
import { getSearchParams } from '@/ducks/routes/history';
import { formatDayByDate } from '@/helpers/util/dateUtil';
import { getBookingSource } from '@/helpers/util/misc';
import { getVWOValue } from '@/helpers/vwo/getVWOValue';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { VWOFlag } from '@/types/vwo';

export * from './amount';

export function getGrandTotal(
  cabinInvoice: Partial<TCabinInvoice> | undefined,
  priceObjectKey: 'fullPayPrice' | 'bookingPriceDetails' = 'bookingPriceDetails',
): number {
  const price = cabinInvoice?.[priceObjectKey]?.amount ?? 0;
  const insurance = cabinInvoice?.[priceObjectKey]?.tripInsurance ?? 0;
  return price - insurance;
}

function getFinalPrice(cabinInvoices: TCabinInvoice[], paymentSchedule: TPaymentSchedule[]): number {
  const finalAmount = paymentSchedule.reduce(
    (sum, schedule) => sum + (schedule.finalAmount?.amount ?? 0) + (schedule.depositAmount?.amount ?? 0),
    0,
  );
  const tripInsurance = cabinInvoices.reduce(
    (sum, invoice) => sum + (invoice.bookingPriceDetails?.tripInsurance ?? 0),
    0,
  );
  return finalAmount - tripInsurance;
}

function getDiscount(cabinInvoices: TCabinInvoice[] = []): number {
  return cabinInvoices.reduce((sum, item) => sum + (item.bookingPriceDetails?.discount ?? 0), 0);
}

export function getAppliedDiscounts(discountList: (TBookingPriceDetailDiscount & { amount?: number })[]):
  | {
      codes: string[];
      names: (string | undefined)[];
      totalDiscount: number;
    }
  | undefined {
  if (!discountList?.length) return undefined;
  return discountList.reduce(
    (acc, currentDiscount) => {
      const amount = currentDiscount?.amount ?? 0;
      if (amount < 0) {
        return {
          codes: [...acc.codes, currentDiscount.priceTypeText],
          names: [...acc.names, currentDiscount.name],
          totalDiscount: acc.totalDiscount + amount,
        };
      }
      return acc;
    },
    { codes: [] as string[], names: [] as (string | undefined)[], totalDiscount: 0 },
  );
}

function getDepositAmount(paymentSchedule: TPaymentSchedule[]) {
  return paymentSchedule.reduce((sum, schedule) => sum + (schedule.depositAmount?.amount ?? 0), 0);
}

export function getAmountPerItemFlat(
  price: number,
  options: { cabins?: number; priceType?: FiltersPriceType; sailors?: number },
): number {
  const { cabins = 1, priceType = FiltersPriceType.perSailor, sailors = 1 } = options;
  if (priceType === FiltersPriceType.perCabin || priceType === FiltersPriceType.sailorPerNight) {
    return price / cabins;
  }
  return price / sailors;
}

type TPriceTypeOptions = {
  cabins?: number;
  duration?: number;
  sailors?: number;
};

function dividePriceByType(priceAmount: number, priceType: FiltersPriceType, priceOptions?: TPriceTypeOptions): number {
  const { cabins = 1, duration = 1, sailors = 1 } = priceOptions || {};
  switch (priceType) {
    case FiltersPriceType.perCabin:
      return priceAmount / cabins;
    case FiltersPriceType.sailorPerNight:
      return priceAmount / duration / sailors;
    case FiltersPriceType.perSailor:
    default:
      return priceAmount / sailors;
  }
}

type TPriceDetails = {
  amount?: number;
  taxAmount?: number;
  tripInsurance?: number;
};
type TPriceOptions = TPriceTypeOptions & {
  isInsuranceInclusive?: boolean;
  isTaxInclusive?: boolean;
};

export function calcDisplayPrice(
  priceDetails: TPriceDetails,
  priceType: FiltersPriceType,
  priceOptions?: TPriceOptions,
): number {
  const { isInsuranceInclusive, isTaxInclusive } = priceOptions || {};
  let price = priceDetails?.amount ?? 0;
  if (!isTaxInclusive) {
    price -= priceDetails?.taxAmount ?? 0;
  }
  if (!isInsuranceInclusive) {
    price -= priceDetails?.tripInsurance ?? 0;
  }
  return dividePriceByType(price, priceType, priceOptions);
}

export function getAverageAmountWithoutTax(packages: FilteredPackage[]): number {
  if (!packages.length) return 0;
  const packagePriceList = packages.map((packageData) => {
    if (!packageData?.startingPrice?.originalAmount) return 0;
    const { originalAmount, taxAmount } = packageData.startingPrice;
    return originalAmount - taxAmount;
  });
  const totalVoyagePrice = packagePriceList.reduce((acc, val) => acc + val, 0);
  return totalVoyagePrice / packagePriceList.length;
}

function getCabinPriceItems(cabin: Partial<TCabinInvoice>) {
  const isTaxesAndFeesFeatureEnabled = getVWOValue({ key: VWOFlag.TAXES_AND_FEES });

  return {
    gratuities: {
      name: <UIResource id="VoyagePlanner.Summary.Gratuities" />,
      value: cabin.bookingPriceDetails?.gratuities,
    },
    sailors: (cabin?.guestPriceDetails || []).map((guestDetail, index: number) => ({
      name: `Sailor ${index + 1}`,
      value:
        (guestDetail?.originalAmount ?? 0) -
        (guestDetail?.tripInsurance ?? 0) -
        (guestDetail?.taxAmount ?? 0) -
        (guestDetail?.portFees ?? 0),
    })),
    taxes: {
      name: <UIResource id="VoyagePlanner.Summary.TaxesAndFees" />,
      value: (cabin.bookingPriceDetails?.taxAmount ?? 0) + (cabin.bookingPriceDetails?.portFees ?? 0),
    },
    ...(isTaxesAndFeesFeatureEnabled && {
      govtFees: {
        isNoHeading: true,
        name: <UIResource id="Summary.FareBreakdown.governmentTaxes" />,
        value: cabin.bookingPriceDetails?.taxAmount ?? 0,
      },
      portFees: {
        isNoHeading: true,
        name: <UIResource id="Summary.FareBreakdown.portFees" />,
        value: cabin.bookingPriceDetails?.portFees ?? 0,
      },
    }),
  };
}

function getCabins(cabinInvoices: TCabinInvoice[]) {
  return cabinInvoices.map((cabin) => ({
    addOnPriceDetails: cabin.addOnPriceDetails,
    heading: cabin.name,
    items: getCabinPriceItems(cabin),
    total: (cabin.bookingPriceDetails?.originalAmount ?? 0) - (cabin.bookingPriceDetails?.tripInsurance ?? 0),
    whatsIncluded: cabin.whatsIncluded,
  }));
}

function getSailorsAmount(cabins: ReturnType<typeof getCabins>) {
  return cabins.reduce((sumCabins, cabin) => {
    const sailorsArray = cabin?.items?.sailors ?? [];
    const sumPerCabin = sailorsArray.reduce((acc: number, sailor: { value: number }) => acc + sailor.value, 0);
    return sumCabins + sumPerCabin;
  }, 0);
}

export function getOriginalAmount(cabinInvoices: TCabinInvoice[]): number {
  const first = cabinInvoices[0]?.bookingPriceDetails;
  return (first?.originalAmount ?? 0) - (first?.tripInsurance ?? 0);
}

export function getPriceBreakdownProps(cabinInvoices: TCabinInvoice[], paymentSchedule: TPaymentSchedule[]) {
  const totalAmt = getFinalPrice(cabinInvoices, paymentSchedule);
  const depositAmt = getDepositAmount(paymentSchedule);
  const discount = getDiscount(cabinInvoices);
  const discountList = cabinInvoices[0]?.bookingPriceDetails?.discountList || [];
  const originalAmount = getOriginalAmount(cabinInvoices);
  const cabins = getCabins(cabinInvoices);
  const sailorsAmount = getSailorsAmount(cabins);

  return {
    cabins,
    currencyCode: cabinInvoices[0]?.bookingPriceDetails?.currencyCode,
    deposit: {
      due: 'today',
      dueDate: formatDayByDate(paymentSchedule?.[0]?.finalAmount?.dueDate, 'MMM dd, yyyy'),
      percentage: 20,
      remaining: paymentSchedule?.[0]?.finalAmount?.amount,
      value: depositAmt,
    },
    discount: discount ?? 0,
    discountList,
    numSailors: cabinInvoices[0]?.guestCount,
    originalAmount,
    paidAmount: cabinInvoices[0]?.bookingPriceDetails?.paidAmount || 0,
    sailorsAmount,
    total: totalAmt,
    totalDue: cabinInvoices[0]?.bookingPriceDetails?.totalDue || 0,
  };
}

export function makeUnholdApiArgs(cabinNumber?: string, sailingData?: TSailingData) {
  const { debarkDate = '', embarkDate = '', voyageId = '' } = sailingData || {};
  const { currencyCode = 'USD', shipCode = '' } = getSearchParams();
  return {
    cabinDetails: [
      {
        cabinNumber,
        debarkDate: debarkDate,
        embarkDate: embarkDate,
      },
    ],
    shipCode: shipCode,
    voyageId: voyageId,
    ...getBookingSource(currencyCode, false, false),
  };
}
