export const enum AgentDataActionType {
  exist = 'EXIST',
  remove = 'REMOVE',
  set = 'SET',
}

export const enum FMSearchParams {
  agencyId = 'agencyId',
  agentId = 'agentId',
  bookingChannel = 'bookingChannel',
}
